import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { stravaReducer } from './stravaReducer';
import { fitbitReducer } from './fitbitReducer';
import { apiTestReducer } from './apiTestReducer';
import { fitnessReducer } from './fitnessReducer';
import { connectedSourcesReducer } from './connectedSourcesReducer';
import { reducer as formReducer } from 'redux-form';
import { persistReducer, purgeStoredState } from 'redux-persist';
// import { expireReducer } from 'redux-persist-expire';
import storage from 'redux-persist/lib/storage';
import AuthActionTypes from '../actions/types';

const authPersistConfig = {
  key: 'auth',
  storage: storage,
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  strava: stravaReducer,
  fitbit: fitbitReducer,
  apiTest: apiTestReducer,
  fitness: fitnessReducer,
  connections: connectedSourcesReducer,
  form: formReducer,
});

const rootReducer = (state: any, action: any) => {
  console.log('rootReducer state', state);
  console.log('rootReducer  action', action);
  if (action.type === AuthActionTypes.LOGOUT) {
    storage.removeItem('persist:root');
    purgeStoredState(authPersistConfig);
    state = undefined;
  }

  console.log('rootReducer', action);

  return appReducer(state, action);
};

export default rootReducer;
