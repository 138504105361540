import { ConnectedSourcesTypes } from '../actions/types';

interface ConnectedSourcesAction {
  type: ConnectedSourcesTypes;
  payload: any;
}

export const connectedSourcesReducer = (
  state: any = {},
  action: ConnectedSourcesAction
) => {
  switch (action.type) {
    case ConnectedSourcesTypes.GETCONNETEDSOURCES:
      return { ...state, error: '', connectedSources: action.payload };

    default:
      return { ...state, error: '', connectedSources: undefined };
  }
};
