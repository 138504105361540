import axios, { AxiosResponse } from 'axios';
import {
  Activity,
  ConnectedSources,
  FitbitSleep,
  FitbitSummary,
} from '../models/Fitness';

const api = axios.create({
  baseURL: 'https://untappedfitness1-api.themarkkell.com/v1',
});

export const stravaConfirmAuth = async (
  confirmAuthToken: string,
  apiAuthToken: string
): Promise<AxiosResponse> => {
  const stravaConfirmAuth = '/strava/confirmauth';

  const response = await api.post(
    stravaConfirmAuth,
    { confirmAuthToken },
    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  return response;
};

export const stravaDisconnect = async (
  apiAuthToken: string
): Promise<AxiosResponse> => {
  const stravaConfirmAuthPath = '/strava/disconnect';

  const response = await api.post(
    stravaConfirmAuthPath,
    {},
    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  return response;
};

export const fitbitDisconnect = async (
  apiAuthToken: string
): Promise<AxiosResponse> => {
  const stravaConfirmAuthPath = '/fitbit/disconnect';

  const response = await api.post(
    stravaConfirmAuthPath,
    {},
    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  return response;
};

export const fitConfirmAuth = async (
  confirmAuthToken: string,
  apiAuthToken: string
): Promise<AxiosResponse> => {
  const stravaConfirmAuth = '/fitbit/confirmauth';

  const response = await api.post(
    stravaConfirmAuth,
    { confirmAuthToken },
    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  return response;
};

export const getActivities = async (
  apiAuthToken: string,
  activitySource: string = 'all'
): Promise<Activity[]> => {
  const getAllActivities = `/activities/${activitySource}`;

  const response = await api.get<Activity[]>(
    getAllActivities,

    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  return response.data;
};

export const getFitbitSummaries = async (
  apiAuthToken: string
): Promise<FitbitSummary[]> => {
  const getFitbitSummaries = '/fitbit/summary';

  const response = await api.get<FitbitSummary[]>(
    getFitbitSummaries,

    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  console.log('Get Fitbit Summaries response', response);
  return response.data;
};

export const getFitbitSleep = async (
  apiAuthToken: string
): Promise<FitbitSleep[]> => {
  const getFitbitSleep = '/fitbit/sleep';

  const response = await api.get<FitbitSleep[]>(
    getFitbitSleep,

    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  console.log('Get Fitbit Summaries response', response);
  return response.data;
};

export const getConnectedSources = async (
  apiAuthToken: string
): Promise<ConnectedSources[]> => {
  const getFitbitSummaries = '/authStatus';

  const response = await api.get<ConnectedSources[]>(
    getFitbitSummaries,

    {
      headers: {
        Authorization: `${apiAuthToken}`,
      },
    }
  );

  console.log('Get Fitbit Summaries response', response);
  return response.data;
};

export const protectedEndpoint = async (): Promise<AxiosResponse> => {
  const unprotectedApi = '/unprotected';
  const response = await api.get(unprotectedApi);
  return response;
};
