import AuthActionTypes from '../actions/types';

interface AuthAction {
  type: AuthActionTypes;
  payload: any;
}

export const authReducer = (state: any = {}, action: AuthAction): boolean => {
  console.log('auth reducer');
  console.log(action);
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      if (action.payload.error) {
        return { ...state, error: action.payload.error };
      }

      console.log('authReducer LOGIN Action');
      const jwt = action.payload.jwt;
      const idToken = action.payload.idToken;
      return { ...state, jwt, idToken, loggedIn: true, error: '' };

    case AuthActionTypes.LOGOUT:
      console.log('authReducer LOGOUT Action');
      return { ...state, loggedIn: false, idToken: undefined, jwt: undefined };
    case AuthActionTypes.REGISTER:
      console.log(' authReducer register Action');

      return { ...state, loggedIn: true };

    default:
      return { ...state, error: '' };
  }
};
