import React from 'react';
import { connect } from 'react-redux';
import { disconnectAction } from '../../actions/fitbitAction';
import { getConnectedSourcesAction } from '../../actions/connectedSourcesAction';

interface Props {
  disconnectAction: () => void;
  getConnectedSourcesAction: () => void;
}


const StravaDisconnect: React.FC<Props> = (props: Props) => {
  const onDisconnect = () => {
    props.disconnectAction();
    props.getConnectedSourcesAction();
  };

  return (
    <div>
      <div>
        <button onClick={onDisconnect}>Disconnet from Fitbit</button>
      </div>
    </div>
  );
};

export default connect(null, {
  disconnectAction,
  getConnectedSourcesAction,
})(StravaDisconnect);
