import { FitnessActionTypes } from './types';
import * as untapepdApi from '../api/untappedApi';

export const getActivitiesAction = () => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;
  const activities = await untapepdApi.getActivities(cognitoIdToken);

  dispatch({
    type: FitnessActionTypes.GETACTIVITIES,
    payload: activities,
  });
};
