import { ApiTestActionTypes } from '../actions/types';

interface ApiAction {
  type: ApiTestActionTypes;
  payload: any;
}

export const apiTestReducer = (state: any = {}, action: ApiAction) => {
  switch (action.type) {
    case ApiTestActionTypes.AUTHED:
      return { ...state, authedPayload: action.payload };
    case ApiTestActionTypes.UNAUTHED:
      return { ...state, unauthedPayload: action.payload };
    default:
      return state;
  }
};
