import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import FitbitSummary from './FitbitSummary';
import { getConnectedSourcesAction } from '../../actions/connectedSourcesAction';
import FitbitDisconnect from './FitbitDisconnect';

interface State {
  connections: {
    connectedSources: {
      strava: boolean;
      fitbit: boolean;
    };
  };
}

interface Props {
  getConnectedSourcesAction: () => void;
  connections: {
    strava: boolean;
    fitbit: boolean;
  };
}

const Fitbit = (props: Props) => {
  const clientId = '22C87R';
  const redirectUrl = `${window.location.href}/confirm`;
  const scope = 'activity heartrate sleep';

  useEffect(() => {
    if (!props.connections) {
      props.getConnectedSourcesAction();
    }
  }, [props]);

  const renderFitbitConnectLink = () => {
    return (
      <div>
        <a
          href={`https://www.fitbit.com/oauth2/authorize?client_id=${clientId}&response_type=code&redirect_url=${redirectUrl}&scope=${scope}`}
        >
          Connect to Fitbit
        </a>
      </div>
    );
  };

  const renderFitbitDisconnectButton = () => {
    return (
      <div>
        <FitbitDisconnect />
      </div>
    );
  };

  return (
    // if not connected then display link, TODO: Call is connected endpoint (which doesn't exist uyet)
    <div>
      {props.connections &&
        !props.connections.fitbit &&
        renderFitbitConnectLink()}
      {props.connections &&
        props.connections.fitbit &&
        renderFitbitDisconnectButton()}

      <div style={{ padding: 20 }}>
        <FitbitSummary />{' '}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    connections: state.connections.connectedSources,
  };
};

export default connect(mapStateToProps, { getConnectedSourcesAction })(Fitbit);
