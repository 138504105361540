import React from 'react';
import { connect } from 'react-redux';
import { registerAction } from '../../actions/authAction';
import User from '../../models/User';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { validateRegister } from './Validate';
import ReactFormInput from './ReduxFormInput';

interface Props {
  registerAction: (user: User) => void;
  errorMessage: string;
}

class RegistrationForm extends React.Component<
  Props & InjectedFormProps<{}, Props>
> {
  onSubmit = (values: any) => {
    const user: User = {
      firstName: values.firstname,
      lastName: values.lastname,
      email: values.email,
      password: values.password,
    };
    registerAction(user);
  };

  render() {
    return (
      <>
        <h3>Register</h3>
        <label className='text-danger'>{this.props.errorMessage}</label>

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className='form-group'>
            <label htmlFor='firstname'>Firstname</label>
            <Field
              type='text'
              component={ReactFormInput}
              className='form-control'
              placeholder='enter first name'
              name='firstname'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='lastname'>Surname</label>
            <Field
              type='text'
              component={ReactFormInput}
              className='form-control'
              placeholder='enter surname'
              name='lastname'
            />
          </div>

          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <Field
              type='email'
              component={ReactFormInput}
              className='form-control'
              placeholder='enter email'
              name='email'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <Field
              type='password'
              component={ReactFormInput}
              className='form-control'
              placeholder='enter password'
              name='password'
            />
          </div>

          <button className='btn btn-primary'>Register</button>
        </form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    errorMessage: 'this is a test',
  };
};

const form = reduxForm<{}, Props>({
  form: 'Register',
  validate: validateRegister,
})(RegistrationForm);

export default connect(mapStateToProps, { registerAction })(form);
