import React from 'react';
import { connect } from 'react-redux';
import Activities from './Activities';
import background from '../images/fitnessImage.jpeg';

interface Props {
  userLoggedIn: boolean;
}

const HomePage = (props: Props) => {
  const renderActivities = () => {
    return <Activities />;
  };

  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <div
        style={{
          position: 'absolute',
          backgroundImage: `url(${background})`,
          backgroundSize: '100% 100%',
          opacity: 0.2,
        }}
      ></div>
      {props.userLoggedIn && renderActivities()}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userLoggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, {})(HomePage);
