import AuthActionTypes from './types';
import history from '../history';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  ISignUpResult,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import User from '../models/User';
import { config } from '../config';

export const registerAction = (user: User) => async (dispatch: any) => {
  console.log('REGISTER Action Triggered');
  const userPool = getCognitoUserPool();

  const firstName = {
    Name: 'given_name',
    Value: user.firstName,
  };

  const lastName = {
    Name: 'family_name',
    Value: user.lastName,
  };

  const firstNameAttribute = new CognitoUserAttribute(firstName);
  const lastnameAttribute = new CognitoUserAttribute(lastName);
  const userAttributes = [firstNameAttribute, lastnameAttribute];

  const signupPromise = (): Promise<ISignUpResult> => {
    return new Promise((resolve, reject) => {
      userPool.signUp(
        user.email,
        user.password,
        userAttributes,
        [],
        (err: any, result: ISignUpResult | undefined) => {
          if (err) {
            reject(err);
          }

          if (result) {
            resolve(result);
          }
        }
      );
    });
  };

  try {
    const response = await signupPromise();

    dispatch({
      type: AuthActionTypes.REGISTER,
      payload: response.userSub,
    });
  } catch (err) {
    dispatch({
      type: AuthActionTypes.REGISTER,
      payload: { error: err.message },
    });
  }
};

export const loginAction = (email: string, password: string) => async (
  dispatch: any
) => {
  console.log('Login Action Triggered', email, password);

  const userPool = getCognitoUserPool();

  const authenticationData = {
    Username: email,
    Password: password,
  };

  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
    Username: email,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  const authenticatePromise = () => {
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve(result);
        },
        onFailure: function (err) {
          reject(err);
        },
      });
    });
  };

  try {
    const authResponse = await authenticatePromise();

    const jwt = (authResponse as CognitoUserSession)
      .getAccessToken()
      .getJwtToken();

    console.log('GETTING TOKENS AGAIN');
    const idToken = (authResponse as CognitoUserSession)
      .getIdToken()
      .getJwtToken();

    history.push('/');
    dispatch({
      type: AuthActionTypes.LOGIN,
      payload: { userSession: authResponse, jwt, idToken },
    });
  } catch (err: any) {
    dispatch({
      type: AuthActionTypes.LOGIN,
      payload: { error: err.message },
    });
  }
};

export const logoutAction = () => {
  console.log('LOGOUT Action Triggered');
  return { type: AuthActionTypes.LOGOUT, payload: {} };
};

const getCognitoUserPool = (): CognitoUserPool => {
  const data = {
    UserPoolId: config.cognito.userPoolId,
    ClientId: config.cognito.clientId,
  };

  const userPool = new CognitoUserPool(data);
  return userPool;
};
