import { ConnectedSourcesTypes } from './types';
import * as untappedApi from '../api/untappedApi';

export const getConnectedSourcesAction = () => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const connectedSourcesResponse = await untappedApi.getConnectedSources(
    cognitoIdToken
  );

  dispatch({
    type: ConnectedSourcesTypes.GETCONNETEDSOURCES,
    payload: connectedSourcesResponse,
  });
};
