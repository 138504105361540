import { StravaActionTypes } from './types';
import * as stravaApi from '../api/untappedApi';
import * as untappdApi from '../api/untappedApi';

export const getStravaActivitiesAction = () => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const fitbitSummaryResponse = await untappdApi.getActivities(
    cognitoIdToken,
    'strava'
  );

  dispatch({
    type: StravaActionTypes.GETACTIVITIES,
    payload: fitbitSummaryResponse,
  });
};

export const confirmAuthAction = (stravaToken: string) => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const confirmAuthResponse = await stravaApi.stravaConfirmAuth(
    stravaToken,
    cognitoIdToken
  );

  console.log('CONFIRM AUTH RESPONSe', confirmAuthResponse);

  dispatch({
    type: StravaActionTypes.CONFIRMAUTH,
    payload: { authConfirmed: true },
  });
};

export const disconnectAction = () => async (dispatch: any, getState: any) => {
  const cognitoIdToken = getState().auth.idToken;

  const confirmAuthResponse = await stravaApi.stravaDisconnect(cognitoIdToken);

  console.log('Disconnect Response', confirmAuthResponse);

  dispatch({
    type: StravaActionTypes.DISCONNECT,
    payload: { authConfirmed: true },
  });
};
