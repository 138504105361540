import axios from 'axios';
import { ApiTestActionTypes } from './types';

const baseUrl = 'https://untappedfitness1-api.themarkkell.com/v1';

export const unauthedAction = () => async (dispatch: any) => {
  const unprotectedApi = '/unprotected';

  const api = axios.create({
    baseURL: baseUrl,
  });

  const response = await api.get(unprotectedApi);

  dispatch({
    type: ApiTestActionTypes.UNAUTHED,
    payload: response.data.response,
  });
};

export const authedAction = () => async (dispatch: any, getState: any) => {
  const protectedApi = '/protected';

  const api = axios.create({
    baseURL: baseUrl,
  });

  const idToken = getState().auth.idToken;

  const response = await api.get(protectedApi, {
    headers: {
      Authorization: `${idToken}`,
    },
  });

  dispatch({
    type: ApiTestActionTypes.AUTHED,
    payload: response.data.response,
  });
};
