import React from 'react';
import './App.css';
import Header from './Header';
import { Route } from 'react-router';
import { Router, Switch } from 'react-router-dom';
import HomePage from './HomePage';
import LoginForm from './auth/LoginForm';
import RegistrationForm from './auth/RegistrationForm';
import history from '../history';
import ApiTest from './ApiTest';
import Strava from './strava/Strava';
import Fitbit from './fitbit/Fitbit';
import StravaConfirmAuth from './strava/StravaConfirmAuth';
import FitbitConfirmAuth from './fitbit/FitbitConfirmAuth';

function App() {
  return (
    <div className='container'>
      <Router history={history}>
        <Header />
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route exact path='/login'>
            <LoginForm />
          </Route>
          <Route exact path='/register'>
            <RegistrationForm />
          </Route>
          <Route exact path='/apitest'>
            <ApiTest />
          </Route>
          <Route exact path='/strava'>
            <Strava />
          </Route>
          <Route path='/strava/confirm'>
            <StravaConfirmAuth />
          </Route>
          <Route exact path='/fitbit'>
            <Fitbit />
          </Route>
          <Route path='/fitbit/confirm'>
            <FitbitConfirmAuth />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
