import { FitbitActionTypes } from '../actions/types';

interface FitbitAction {
  type: FitbitActionTypes;
  payload: any;
}

export const fitbitReducer = (state: any = {}, action: FitbitAction) => {
  console.log('fitbit auth reducer');
  console.log(state);
  switch (action.type) {
    case FitbitActionTypes.CONFIRMAUTH:
      return { ...state, error: '', authConfirmed: true, connected: true };

    case FitbitActionTypes.GETSUMMARY:
      return { ...state, activitySummary: action.payload };

    case FitbitActionTypes.GETSLEEP:
      return { ...state, sleepSummary: action.payload };

    case FitbitActionTypes.DISCONNECT:
      return { ...state, connected: false };

    default:
      return { ...state, error: '' };
  }
};
