import { StravaActionTypes } from '../actions/types';

interface StravaAction {
  type: StravaActionTypes;
  payload: any;
}

export const stravaReducer = (state: any = {}, action: StravaAction) => {
  console.log('auth reducer');
  switch (action.type) {
    case StravaActionTypes.CONFIRMAUTH:
      return { ...state, error: '', authConfirmed: true, connected: true };
    case StravaActionTypes.GETACTIVITIES:
      return { ...state, activities: action.payload };
    case StravaActionTypes.DISCONNECT:
      return { ...state, connected: false };

    default:
      return { ...state, error: '' };
  }
};
