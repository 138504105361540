import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { loginAction } from '../../actions/authAction';
import { validateLogin } from './Validate';
import ReduxFormInput from './ReduxFormInput';

interface MyProps {
  loginAction: (username: string, password: string) => {};
  myProblem: string;
}

const LoginForm: React.FC<MyProps & InjectedFormProps<{}, MyProps>> = (
  props
) => {
  const formSubmit = (values: any) => {


    props.loginAction(values.email, values.password);
  };

  return (
    <div className='login'>
      <h3>Login</h3>
      <label className='text-danger'>{props.myProblem}</label>
      <form onSubmit={props.handleSubmit(formSubmit)}>
        <div className='form-group'>
          <label htmlFor='email'>Email</label>
          <Field
            type='email'
            className='form-control'
            name='email'
            component={ReduxFormInput}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='password'>Password</label>
          <Field
            type='password'
            className='form-control'
            name='password'
            component={ReduxFormInput}
          />
        </div>
        <button className='btn btn-primary'>Login</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    myProblem: state.auth.error,
  };
};

const form = reduxForm<{}, MyProps>({
  form: 'Login',
  validate: validateLogin,
})(LoginForm);

export default connect(mapStateToProps, { loginAction })(form);
