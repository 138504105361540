import React from 'react';
import { Link } from 'react-router-dom';
import LoginLogoutToggle from './auth/LoginLogoutToggle';
import { connect } from 'react-redux';

interface Props {
  userLoggedIn: boolean;
}

const Nav = (props: Props) => {
  const renderLoggedInNav = () => {
    return (
      <>
        <li className='nav-item'>
          <Link to='/apitest' className='nav-link'>
            Logged in Api Test
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/strava'>
            Strava
          </Link>
        </li>
        <li className='nav-item'>
          <Link className='nav-link' to='/fitbit'>
            Fitbit
          </Link>
        </li>
        <li className='nav-item'>
          <a className='nav-link' href='/'>
            Untapped
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link disabled' href='/'>
            Disabled
          </a>
        </li>
      </>
    );
  };
  const renderLoggedOutNav = () => {
    return (
      <>
        <li className='nav-item'>
          <Link to='/apitest' className='nav-link'>
            Logged out Api Test
          </Link>
        </li>
      </>
    );
  };

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <a className='navbar-brand' href='/'>
        Navbar
      </a>
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav'>
          <li className='nav-item active'>
            <Link to='/' className='nav-link'>
              Home <span className='sr-only'>(current)</span>
            </Link>
          </li>
          {props.userLoggedIn && renderLoggedInNav()}
          {!props.userLoggedIn && renderLoggedOutNav()}
        </ul>
      </div>
      <LoginLogoutToggle />
    </nav>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userLoggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(Nav);
