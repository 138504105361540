import { FitbitActionTypes } from './types';
import * as untappedApi from '../api/untappedApi';

export const getFitbitSummaryAction = () => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const fitbitSummaryResponse = await untappedApi.getFitbitSummaries(
    cognitoIdToken
  );

  dispatch({
    type: FitbitActionTypes.GETSUMMARY,
    payload: fitbitSummaryResponse,
  });
};

export const getFitbitSleepAction = () => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const fitbitSummaryResponse = await untappedApi.getFitbitSleep(
    cognitoIdToken
  );

  dispatch({
    type: FitbitActionTypes.GETSLEEP,
    payload: fitbitSummaryResponse,
  });
};

export const confirmFitbitAuthAction = (fitbitConfirmToken: string) => async (
  dispatch: any,
  getState: any
) => {
  const cognitoIdToken = getState().auth.idToken;

  const confirmAuthResponse = await untappedApi.fitConfirmAuth(
    fitbitConfirmToken,
    cognitoIdToken
  );

  console.log('Fitbit CONFIRM AUTH RESPONSe', confirmAuthResponse);

  dispatch({
    type: FitbitActionTypes.CONFIRMAUTH,
    payload: { authConfirmed: true },
  });
};

export const disconnectAction = () => async (dispatch: any, getState: any) => {
  const cognitoIdToken = getState().auth.idToken;

  const confirmAuthResponse = await untappedApi.fitbitDisconnect(
    cognitoIdToken
  );

  console.log('Disconnect Response', confirmAuthResponse);

  dispatch({
    type: FitbitActionTypes.DISCONNECT,
    payload: { authConfirmed: true },
  });
};
