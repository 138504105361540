import React from 'react';
import { connect } from 'react-redux';
import { unauthedAction, authedAction } from '../actions/apiTests';

interface Props {
  unauthedAction: () => void;
  authedAction: () => void;
  authedResponse: string;
  unauthedResponse: string;
}

const ApiTest = (props: Props) => {
  const authenticatedClick = () => {
    props.authedAction();
  };

  const unAuthenticatedClick = () => {
    props.unauthedAction();
  };

  return (
    <div>
      <div>
        <button onClick={authenticatedClick}>Test Authenticated API</button>
        <label>Authed Response:</label>
        <label>{props.authedResponse}</label>
      </div>

      <div>
        <button onClick={unAuthenticatedClick}>
          Test Un-Authenticated API
        </button>
        <label>UnAuthed Response:</label>
        <label>{props.unauthedResponse} </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    authedResponse: state.apiTest.authedPayload,
    unauthedResponse: state.apiTest.unauthedPayload,
  };
};

export default connect(mapStateToProps, { unauthedAction, authedAction })(
  ApiTest
);
