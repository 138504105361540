import React from 'react';
import { Activity } from '../models/Fitness';
// import moment from 'moment';

interface MyProps {
  activity: Activity;
}

export const ActivityCard: React.FC<MyProps> = (props: MyProps) => {
  const getCardColour = (source: string) => {
    return source === 'fitbit' ? 'light' : 'danger';
  };

  const secondsToHms = (d: number) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
  };

  const getMiles = (metres: number) => {
    return (metres * 0.000621371192).toFixed(2);
  };

  const isFitbit = (source: string) => {
    return source === 'fitbit';
  };

  const a = props.activity;
  // const dateString = moment(a.startDate).format('hh:mm:ss');
  const dateString = new Date(a.startDate).toLocaleTimeString();

  return (
    <div
      className={`card bg-${getCardColour(a.source)} mb-3`}
      style={{ maxWidth: '18rem' }}
    >
      <div className='card-header'>{`${a.name} (${a.source})`} </div>
      <div className='card-body'>
        <div className='card-title'>Time of day: {dateString}</div>
        <div className='card-text'>
          Distance:{' '}
          <strong>
            {' '}
            {isFitbit(a.source) ? a.distance : `${getMiles(a.distance)} miles`}
            {isFitbit(a.source) && ' steps'}
          </strong>
        </div>
        <div className='card-text'>
          Duration: <strong> {secondsToHms(a.duration)}</strong>
        </div>
        <div className='card-text'>
          Calories: <strong>{a.calories}</strong>
        </div>
        <div className='card-text'></div>
      </div>
    </div>
  );
};

export default ActivityCard;
