import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getStravaActivitiesAction } from '../../actions/stravaAction';
import { Activity } from '../../models/Fitness';
// import moment from 'moment';
import ActivityCard from '../ActivityCard';

interface Props {
  activities: Activity[];
  getStravaActivitiesAction: () => void;
}

interface State {
  strava: {
    activities: Activity[];
  };
}

const StravaSummary: React.FC<Props> = (props: Props) => {
  useEffect(() => {
    if (!props.activities) {
      props.getStravaActivitiesAction();
    }
  }, [props]);

  // const getFriendlyDate = (d: string) => {
  //   const newDate = Date.parse(d);
  //   return moment(newDate).format('DD MMM yyyy:  H:mm:ss');
  // };

  if (!props.activities || props.activities.length === 0) {
    return <div>Loading... </div>;
  }

  const renderSummary = (data: Activity[]) => {
    return (
      <div>
        {data.map((a) => {
          return <ActivityCard key={a.id} activity={a} />;
        })}
      </div>
    );
  };

  return (
    <div>
      <div>{renderSummary(props.activities)}</div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    activities: state.strava.activities,
  };
};

export default connect(mapStateToProps, { getStravaActivitiesAction })(
  StravaSummary
);
