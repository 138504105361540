import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { confirmFitbitAuthAction } from '../../actions/fitbitAction';

const FitbitConfirmAuth: React.FunctionComponent = (props: any) => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  console.log('CONFIRMING');
  props.confirmFitbitAuthAction(code);

  console.log('CONFIRMED');
  return (
    <div>
      <h1>Fitbit Confirm Auth</h1>
      <div>Code: {code}</div>
    </div>
  );
};

export default connect(null, { confirmFitbitAuthAction })(FitbitConfirmAuth);
