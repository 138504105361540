import React, { useEffect } from 'react';
import StravaSummary from '../strava/StravaSummary';
import StravaDisconnect from '../strava/StravaDisconnect';
import { connect } from 'react-redux';
import { getConnectedSourcesAction } from '../../actions/connectedSourcesAction';

const clientId = '30696';
const redirectUrl = `${window.location.href}/confirm`;
const scope = 'read,activity:read';

interface State {
  connections: {
    connectedSources: {
      strava: boolean;
      fitbit: boolean;
    };
  };
}

interface Props {
  getConnectedSourcesAction: () => void;
  connections: {
    strava: boolean;
    fitbit: boolean;
  };
}

const Strava = (props: Props) => {
  useEffect(() => {
    if (!props.connections) {
      props.getConnectedSourcesAction();
    }
  }, [props]);

  if (props.connections && props.connections.strava) {
    return (
      <div>
        <StravaDisconnect />
        <StravaSummary />
      </div>
    );
  }

  return (
    <div>
      <a
        href={`https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&scope=${scope}`}
      >
        Connect to Strava
      </a>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    connections: state.connections.connectedSources,
  };
};

export default connect(mapStateToProps, { getConnectedSourcesAction })(Strava);
