import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getActivitiesAction } from '../actions/fitnessAction';
import { Activity } from '../models/Fitness';
import ActivityCard from './ActivityCard';

interface MyProps {
  fitnessActivities: Activity[];
  getActivitiesAction: () => void;
}

interface State {
  fitness: {
    activities: Activity[];
  };
}

export const Activities: React.FC<MyProps> = (props: MyProps) => {
  useEffect(() => {
    if (!props.fitnessActivities) {
      props.getActivitiesAction();
    }
  }, [props]);

  // Accepts the array and key
  const groupBy = (array: Activity[], key: string) => {
    // Return the end result
    return array.reduce(
      (result: { [key: string]: Activity[] }, currentValue: any) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      },
      {}
    ); // empty object is the initial value for result object
  };

  const renderActivities = (activities: Activity[]) => {
    if (!activities) {
      return <div>Loading</div>;
    }

    console.log('Activities', activities);

    const activitiesWithDate = activities
      .filter((a) => a)
      .map((a) => {
        return { date: new Date(a.startDate).toDateString(), ...a };
      });

    const activitiesPerDay = groupBy(activitiesWithDate, 'date');
    const sortedActivityDates = Object.keys(activitiesPerDay).sort(
      (key1, key2) => {
        return new Date(key1) > new Date(key2) ? -1 : 1;
      }
    );

    return (
      <div className='container-fluid'>
        {sortedActivityDates.map((date) => {
          const activities = activitiesPerDay[date];

          return (
            <>
              <div className='row'>
                <div>
                  <h3>{date}</h3>
                </div>
              </div>
              <div className='row'>
                {activities.map((a) => {
                  return (
                    <div className='col'>
                      <ActivityCard activity={a} />
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    );
  };

  return <div>{renderActivities(props.fitnessActivities)}</div>;
};

const mapStateToProps = (state: State) => {
  return {
    fitnessActivities: state.fitness.activities,
  };
};

export default connect(mapStateToProps, { getActivitiesAction })(Activities);
