import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getFitbitSummaryAction,
  getFitbitSleepAction,
} from '../../actions/fitbitAction';
import {
  FitbitSleep,
  FitbitSummary as FitbitSummaryData,
} from '../../models/Fitness';
import moment from 'moment';

interface Props {
  dailySummaries: FitbitSummaryData[];
  sleepSummaries: FitbitSleep[];
  getFitbitSummaryAction: () => void;
  getFitbitSleepAction: () => void;
}

interface State {
  fitbit: {
    activitySummary: FitbitSummaryData[];
    sleepSummary: FitbitSleep[];
  };
}

const FitbitSummary: React.FC<Props> = (props: Props) => {
  const [selectedTab, setSelectedTab] = useState('summary');

  useEffect(() => {
    if (!props.dailySummaries) {
      props.getFitbitSummaryAction();
    }
    if (!props.sleepSummaries) {
      props.getFitbitSleepAction();
    }
  }, [props]);

  const getFriendlyDate = (d: string) => {
    const newDate = Date.parse(d);
    return moment(newDate).format('DD MMM yyyy:  H:mm:ss');
  };

  const renderSummary = (data: FitbitSummaryData[]) => {
    return (
      <div>
        {data.map((summary) => {
          return (
            <div className='card mb-3' style={{ maxWidth: '20rem' }}>
              <div className='card-header'>Date: {summary.date}</div>
              <div className='card-body'>
                <div>HR:{summary.restingHeartRate} </div>
                <div>Steps:{summary.steps} </div>
                <div>Very Active minutes:{summary.veryActiveMinutes} </div>
                <div>Elevation:{summary.elevation} </div>
                <div>Date Updated:{getFriendlyDate(summary.dateUpdated)} </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSleep = (data: FitbitSleep[]) => {
    return (
      <div>
        {data.map((sleep) => {
          return (
            <div className='card mb-3' style={{ maxWidth: '20rem' }}>
              <div className='card-header'>Date: {sleep.date} </div>
              <div className='card-body'>
                <div>Deep:{sleep.stages.deep} </div>
                <div>Light:{sleep.stages.light} </div>
                <div>Rem:{sleep.stages.rem} </div>
                <div>Wake:{sleep.stages.wake} </div>
                <div>Total Time asleep:{sleep.totalMinutesAsleep} </div>
                <div>Total Time in bed:{sleep.totalTimeInBed} </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  if (!props.dailySummaries || props.dailySummaries.length === 0) {
    return <div>Loading... </div>;
  }

  const getTabStatus = (tab: string) => {
    if (tab === selectedTab) {
      return 'active';
    }
    return '';
  };

  const onTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <ul className='nav nav-tabs'>
        <li className='nav-item'>
          <button
            onClick={() => onTabClick('summary')}
            className={`nav-link ${getTabStatus('summary')}`}
          >
            Summary
          </button>
        </li>
        <li className='nav-item'>
          <button
            onClick={() => onTabClick('sleep')}
            className={`nav-link ${getTabStatus('sleep')}`}
          >
            Sleep
          </button>
        </li>
      </ul>

      <div>
        {selectedTab === 'summary'
          ? renderSummary(props.dailySummaries)
          : renderSleep(props.sleepSummaries)}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    dailySummaries: state.fitbit.activitySummary,
    sleepSummaries: state.fitbit.sleepSummary,
  };
};

export default connect(mapStateToProps, {
  getFitbitSummaryAction,
  getFitbitSleepAction,
})(FitbitSummary);
