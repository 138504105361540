import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { confirmAuthAction } from '../../actions/stravaAction';

const StravaConfirmAuth: React.FunctionComponent = (props: any) => {
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  props.confirmAuthAction(code);

  return (
    <div>
      <h1>Strava Confirm Auth</h1>
      <div>Code: {query.get('code')}</div>
    </div>
  );
};

export default connect(null, { confirmAuthAction })(StravaConfirmAuth);
