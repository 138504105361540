import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutAction } from '../../actions/authAction';

interface Props {
  loggedIn: boolean;
  logoutAction: () => {};
}

const LoginLogoutToggle: FunctionComponent<Props> = (props: Props) => {
  const handleLogout = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.logoutAction();
  };

  const renderLoginLogoutLink = (userLoggedIn: boolean) => {

    if (userLoggedIn) {
      return (
        <form onSubmit={handleLogout}>
          <button className='btn btn-primary'>Logout</button>
        </form>
      );
    } else {
      return (
        <div>
          <Link to='/login' className='nav-link'>
            Login
          </Link>
          <Link to='/Register' className='nav-link'>
            Register
          </Link>
        </div>
      );
    }
  };

  return (
    <div className='loginLogout'>
      {renderLoginLogoutLink(props.loggedIn ?? false)}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps, { logoutAction })(LoginLogoutToggle);
