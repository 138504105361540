import { FormErrors } from 'redux-form';

export const validateLogin = (values: any): FormErrors<any> => {
  const errors: FormErrors<any> = {};

  if (!values.email) {
    errors.email = 'Email Required';
  }

  if (!values.password) {
    errors.password = 'Password Required';
  }

  return errors;
};

export const validateRegister = (values: any): FormErrors<any> => {
  const errors: FormErrors<any> = {};

  if (!values.firstname) {
    errors.firstname = 'Firstname Required';
  }

  if (!values.lastname) {
    errors.lastname = 'Surname Required';
  }

  if (!values.email) {
    errors.email = 'Email Required';
  }

  if (!values.password) {
    errors.password = 'Password Required';
  }

  return errors;
};
