import { FitnessActionTypes } from '../actions/types';
import { Activity } from '../models/Fitness';

interface FitnessAction {
  type: FitnessActionTypes;
  payload: Activity[];
}

export const fitnessReducer = (state: any = {}, action: FitnessAction) => {
  console.log('fitness reducer');
  switch (action.type) {
    case FitnessActionTypes.GETACTIVITIES:
      return { ...state, activities: action.payload };

    default:
      return { ...state };
  }
};
