enum AuthActionTypes {
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
}

export default AuthActionTypes;

export enum ApiTestActionTypes {
  AUTHED = 'authed',
  UNAUTHED = 'unauthed',
}

export enum StravaActionTypes {
  CONFIRMAUTH = 'confirmAuth',
  GETACTIVITIES = 'getStravaActivities',
  DISCONNECT = 'disconnect',
}

export enum FitbitActionTypes {
  CONFIRMAUTH = 'confirmAuth',
  GETSUMMARY = 'getSummary',
  GETSLEEP = 'getSleep',
  DISCONNECT = 'disconnect',
}

export enum FitnessActionTypes {
  GETACTIVITIES = 'getAllActivities',
}

export enum ConnectedSourcesTypes {
  GETCONNETEDSOURCES = 'getConnectedSources',
}
