import React from 'react';
import { connect } from 'react-redux';
import { disconnectAction } from '../../actions/stravaAction';
import { getConnectedSourcesAction } from '../../actions/connectedSourcesAction';

interface Props {
  disconnectAction: () => void;
  getConnectedSourcesAction: () => void;
  // authedResponse: string;
  // unauthedResponse: string;
}

// interface State {
//   strava: {
//     activities: Activity[];
//   };
//}

const StravaDisconnect: React.FC<Props> = (props: Props) => {
  const onDisconnect = () => {
    props.disconnectAction();
    props.getConnectedSourcesAction();
  };

  return (
    <div>
      <div>
        <button onClick={onDisconnect}>Disconnet from Strava</button>
      </div>
    </div>
  );
};

// const mapStateToProps = (state: State) => {
//   return {
//     activities: state.strava.activities,
//   };
// };

export default connect(null, {
  disconnectAction,
  getConnectedSourcesAction,
})(StravaDisconnect);
